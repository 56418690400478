import { MutationTree } from "vuex";
import { AuthState } from "./state";
import { ILatestNotifications } from "@/interfaces/global/interfaces";
import { getAppInstance } from "@/main";

const mutation: MutationTree<AuthState> = {
  loginUser(
    state: AuthState,
    { user, token, first_login, centers, notifications }
  ) {
    if (token) {
      state.isAuthenticated = true;
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("isAuthenticated", "1");
      sessionStorage.setItem("first_login", JSON.stringify(first_login));
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("centers", JSON.stringify(centers));
      state.data.authorization.token = token;
      state.data.user = user;
      state.data.first_login_or_not_change_password = first_login;
      state.data.centers = centers;
      state.data.notifications = notifications;
      state.request.notifications = true;

      setTimeout(() => {
        // Verificar y reinicializar el servicio Ably
        const app = getAppInstance();
        if (app && app.config.globalProperties.$reinitializeAbly) {
          (app.config.globalProperties.$reinitializeAbly as () => void)();
        }
      }, 1000);
    }
  },

  setUpdateProfile(state: AuthState, dataInfo) {
    sessionStorage.setItem("user", JSON.stringify(dataInfo));
    state.data.user = dataInfo;
  },

  maintainSesionUser(state: AuthState) {
    state.isAuthenticated = true;
    sessionStorage.setItem("isAuthenticated", "1");
    /* @ts-ignore */
    state.data.user = JSON.parse(sessionStorage.getItem("user"));
    /* @ts-ignore */
    state.data.authorization.token = sessionStorage.getItem("token");
    /* @ts-ignore */
    state.data.first_login_or_not_change_password =
      sessionStorage.getItem("first_login");

    state.data.centers = JSON.parse(
      sessionStorage.getItem("centers") as string
    );
  },

  logOutUser(state: AuthState) {
    state.isAuthenticated = false;
    sessionStorage.removeItem("isAuthenticated");

    setTimeout(() => {
      // Verificar y reinicializar el servicio Ably
      const app = getAppInstance();
      if (app && app.config.globalProperties.$reinitializeAbly) {
        (app.config.globalProperties.$reinitializeAbly as () => void)();
      }
    }, 1000);
  },

  changePasswordUser(state: AuthState, { token }) {
    state.tokenChangePassword = token;
  },
  setUser(state: AuthState, val) {
    state.data.user = val;
  },
  setNotifications(state: AuthState, data?: ILatestNotifications) {
    if (data) {
      state.data.notifications = data;
      state.request.notifications = true;
    } else {
      state.data.notifications = { data: [], total: 0 };
      state.request.notifications = false;
    }
  },
};

export default mutation;
