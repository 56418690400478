import { ActionTree } from "vuex";
import { UsersState } from "./state";
import { StateInterface } from "../index";
import { authApi, centersApi } from "@/apis";
import { contractsApi } from "@/apis/contractApi";
import { availableCenterRefOptions } from "@/apis/availableApi";

import { IUserFullCustom } from "@/interfaces/customs/userCustom";

const actions: ActionTree<UsersState, StateInterface> = {
  async getUsersByIdRol({ commit }, data) {
    const { token, id, page, center_id = undefined } = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(
        `/getByRol/${id}?page=${page}&centers_id=${center_id}`,
        config
      );
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getUsersByIdRolFilter({ commit }, data) {
    const { token, id, page, filters } = data;
    filters.centers_id;
    const { centers_id, full_reference, specialties_id, status } = filters;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const route = `/getByRol/${id}?page=${page}${
        centers_id !== null ? "&centers_id=" + centers_id : "&null"
      }${
        full_reference !== null ? "&full_reference=" + full_reference : "&null"
      }${
        specialties_id !== null && specialties_id !== undefined
          ? "&specialties_id=" + specialties_id
          : "&null"
      }${status !== null ? "&status=" + status : "&null"}`;
      const { data } = await authApi.get(route, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getAdminCareByIdCc({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getByCenter/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getCentersForType({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await availableCenterRefOptions.get(`/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getFullCenters({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const route = `/getByType/${id}?paginate=false`;
      const { data } = await centersApi().get(route, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getUserByDocument({ commit }, info) {
    const { token, id } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getByDocument/${id}`, config);
      commit("setCenterAdmins", { data });
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
  async getHomeInfo(_, data) {
    const { token } = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getHomeInfo`, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
  async getHomeTrends(_, data) {
    const { token } = data;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.get(`/getHomeTrends`, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
  async editAdmin(_, info) {
    const { user, token } = info;
    const config = {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    };

    try {
      const { data } = await authApi.post(`/register`, { ...user }, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
  async changeUserStatus(_, info) {
    const { id, token } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await authApi.post(`/changeStatus/${id}`, {}, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async getContractById(_, info) {
    const { id, token } = info;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await contractsApi.get(`/${id}`, config);
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },

  async registerUserByRol(_, user: IUserFullCustom) {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token.value}`,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const { data } = await authApi.post(
        "/register",
        { ...user.user },
        config
      );
      return data;
    } catch (error: any) {
      return errorExeption(error);
    }
  },
};

function errorExeption(error: any) {
  //No hay conexion
  if (error.message == "Network Error") {
    const success = false;
    const { message } = error;
    return { success, message };
  }
  const { data } = error.response;
  const { success, message } = data;
  return { success, message };
}

export default actions;
