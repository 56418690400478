import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useResources = () => {
  const store = useStore<StateInterface>();
  return {
    // State
    documentTypes: computed(() => store.state.resources.documentTypes),

    // Actions
    _getResources: (
      query: string, // query = vaccines|procedures|diagnostics|medicines|specialties only
      centers: { reference_center: number; care_center: number },
      userType: number
    ) => store.dispatch("resources/getResources", { query, centers, userType }),
    _getDocumentTypesAdministrators: (documentTypes: string) =>
      store.dispatch("resources/getDocumentTypesAdministrators", documentTypes),
      isGetResourcesPhysicalExam: () =>
      store.dispatch("resources/getResourcesPhysicalExam"),
    _getResource: (resource: string) =>
      store.dispatch("resources/getResource", resource),
    _getDataGraphicHistoricalHeightWeight: (
      entityId: string | number,
      date_initial: string,
      date_end: string
    ) =>
      store.dispatch("resources/getDataGraphicHistoricalHeightWeight", {
        entityId,
        date_initial,
        date_end,
      }),
  };
};