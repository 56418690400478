import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";

export const useUsers = () => {
  interface filters {
    centers_id: null | number | any;
    loading_centers_reference?: boolean;
    specialties_id?: null | number;
    loading_specialties_id?: boolean;
    full_reference: null | string;
    status: null | string;
    loading_status: boolean;
  }
  const store = useStore<StateInterface>();
  return {
    // State
    adminCareCenters: computed(() => store.state.users.adminCenters),
    colorAvatar: computed(() => store.state.users.colorAvatar),
    imgUser: computed(() => store.state.users.imgUser),
    isDashboard: computed(() => store.state.users.isDashboard),
    dataTableTypeUserCurrentPage: computed<number>(
      () => store.state.users.dataTableTypeUser.current_page
    ),
    dataTableTypeUserLastPage: computed<number>(
      () => store.state.users.dataTableTypeUser.last_page
    ),

    // Getters
    getUserAdminEdit: computed(() => store.state.users.adminCareEdit),
    // Actions
    isGetUsersByIdRol: (token: any, id: number, page: any, center_id: number) =>
      store.dispatch("users/getUsersByIdRol", { token, id, page, center_id }),
    isGetUsersByIdRolFilter: (
      token: any,
      id: number,
      page: any,
      filters: filters
    ) =>
      store.dispatch("users/getUsersByIdRolFilter", {
        token,
        id,
        page,
        filters,
      }),
    isGetCentersForType: (info: any) =>
      store.dispatch("users/getCentersForType", info),
    isGetCentersFullForType: (info: any) =>
      store.dispatch("users/getFullCenters", info),
    isGetUserByDocument: (info: any) =>
      store.dispatch("users/getUserByDocument", info),
    isGetgetHomeInfo: (data: any) => store.dispatch("users/getHomeInfo", data),
    isGetgetHomeTrends: (data: any) =>
      store.dispatch("users/getHomeTrends", data),
    isEditAdmin: (user: any, token: any) =>
      store.dispatch("users/editAdmin", { user, token }),
    isChangeUserStatus: (id: any, token: any) =>
      store.dispatch("users/changeUserStatus", { id, token }),
    isGetAdminCareByIdCc: (info: any) =>
      store.dispatch("users/getAdminCareByIdCc", info),
    isRegisterUserByRol: (user: any, token: any) =>
      store.dispatch("users/registerUserByRol", { user, token }),
    isGetContractById: (info: any) =>
      store.dispatch("users/getContractById", info),
    //Mutations
    isSetEditUser: (user: any) => store.commit("users/setEditUser", user),
    isSetColorAvatarUser: (color: any) =>
      store.commit("users/setColorAvatarUser", color),
    isSetImgUser: (img: any) => store.commit("users/setImgUser", img),
    isSetIsDashboard: (val: any) => store.commit("users/setDashboard", val),
    isSetDataTableTypeUser: (data: any) =>
      store.commit("users/setDataTypeUser", data),
  };
};
