import { MutationTree } from 'vuex';
import { UsersState } from './state';


const mutation: MutationTree<UsersState> = {
    setMedicals(state: UsersState, { data }) {
        state.medicals = data;
    },
    setCenterAdmins(state: UsersState, { data }) {
        state.adminCenters = data;
    },
    setEditUser(state: UsersState, data) {
        state.adminCareEdit = data;      
    },
    setColorAvatarUser(state: UsersState, color ) {
        state.colorAvatar = color;
    },
    setImgUser(state: UsersState, img ) {
        state.imgUser = img;
    },
    setDashboard(state: UsersState, val ) {
        state.isDashboard = val;
    },
    setDataTypeUser(state: UsersState, data ) {
        state.dataTableTypeUser = data;
    }
}


export default mutation;