import { ActionTree } from "vuex";
import { AvailableUI } from "./state";
import { StateInterface } from "../index";
import {
  availableApi,
  availableByDoctorApi,
  availableAllApi,
  availableCenterRefOptions,
  availableDoctorsByCenter,
  deleteAvailableApi,
  deleteAvailableByDoctorApi,
  availableUpdateByDoctor,
  createUnavailability,
  deleteAvailableById,
} from "../../apis/availableApi";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { ErrorsUI } from "../contracts/actions";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import {
  IAvailableInfoVardView,
  IUnavailableCreate,
} from "@/interfaces/customs/AvailablesCustomUI";
import { doctorsByCenterSpecialtyApi } from "@/apis/general/generalApi";
import {
  IAvailableBySpecialityDoctor,
  IUpdateAvailable,
} from "@/interfaces/global/Availables";
import { useAlertV2 } from "@/composables/useAlertV2";

interface UpdateEditInfoUI {
  centerRef: string;
  centerRefId: number | null;
  name: string;
  id: number | null;
  speciality: string;
  token: string;
}
const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const actions: ActionTree<AvailableUI, StateInterface> = {
  async UpdateAvailableConfirm({ commit }, ...infoData) {
    commit("setPopupLoadingMessage", "Actualizando disponibilidad...");
    commit("setPopupLoading", true);

    const referenceData = infoData[0].infoData;

    const consolidedInfo = {
      user_id: referenceData.user_id,
      initial_date: referenceData.initial_date,
      final_date: referenceData.final_date,
      initial_hour: referenceData.initial_hour,
      final_hour: referenceData.final_hour,
      interval: referenceData.interval,
      type_id: referenceData.type_id,
      check: true,
    };

    try {
      const updataInfo = await availableUpdateByDoctor().post(
        `/${consolidedInfo.user_id}`,
        consolidedInfo
      );

      const alertMessage = updataInfo.data.message;
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      //redirect
      showAlert(`¡${alertMessage}!`, "success");
      commit("setGoRedirectTo", "AvailableListView");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      //popup
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async UpdateAvailable({ commit }, infoData: IUpdateAvailable) {
    commit("setPopupLoadingMessage", "Actualizando disponibilidad...");
    commit("setPopupLoading", true);

    try {
      const updataInfo = await availableUpdateByDoctor().post(
        `/${infoData.user_id}`,
        infoData
      );

      const alertMessage = updataInfo.data.message;

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      // alert
      showAlert(`¡${alertMessage}!`, "success");

      //redirect
      commit("setGoRedirectTo", "AvailableListView");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const viewError = showCatchError(errorParse);

      if (viewError === "sessionsFound") {
        commit("setAvailableReducerType", "availableUpdateConfirm");
        // popup
        commit("setPopupLoading", false);
        commit("setPopupLoadingMessage", "");
        commit(
          "setPopupTitle",
          "La disponibilidad ya cuenta con telexperticias agendadas"
        );
        commit(
          "setPopupMessage",
          "Al aceptar todas las telexperticias se reagendarán"
        );
      } else {
        //popup
        commit("setPopupLoading", false);
        commit("setPopupOpen", false);
        //alert
        showAlert(String(showCatchError(errorParse)), "error");
      }
    }
  },

  async AvailableEditLoading(
    { commit },
    { infoData }: { infoData: UpdateEditInfoUI }
  ) {
    // loader
    openMainLoader(true);
    try {
      const availableEdit = await availableByDoctorApi().get(`/${infoData.id}`);
      const availableRef = availableEdit.data;
      const availableForm = {
        date: availableRef.date,
        id: availableRef.id,
        first_hour: availableRef.first_hour,
        last_hour: availableRef.last_hour,
        first_date: availableRef.first_date,
        last_date: availableRef.last_date,
        interval: availableRef.interval,
        quota: availableRef.quota,
        status: availableRef.status,
        total_quota: availableRef.total_quota,
        type: availableRef.type,
        updated_at: availableRef.updated_at,
        user_id: availableRef.user_id,
        centerRef: infoData.centerRef,
        centerRefId: infoData.centerRefId,
        name: infoData.name,
        userId: infoData.id,
        speciality: infoData.speciality,
      };

      commit("setAvailableUpdateEditView", availableForm);

      // loader
      openMainLoader(false);

      // alert
      showAlert("¡Se ha cargado la disponibilidad correctamente!", "success");

      return "success";
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      showAlert(String(showCatchError(errorParse)), "error");
      return "error";
    }
  },

  // Delete Available Confirm
  async AfterDeleteAvailableView(
    { commit },
    {
      deleteAvailable,
    }: {
      token: string;
      deleteAvailable: {
        userId: number;
        availableId: number;
      };
      showPage: number;
    }
  ) {
    commit("setPopupLoadingMessage", "Actualizando disponibilidades...");
    commit("setLoadingAvailableTable", true);

    try {
      const infoPages = await availableByDoctorApi().get(
        `/${deleteAvailable.userId}?page=1`
      );
      const alertMessage = infoPages.data.message;
      const lastPage = infoPages.data.data.availabilities.last_page;
      const currentPage = infoPages.data.data.availabilities.current_page;
      const availableData = infoPages.data.data.availabilities.data;
      commit("setAvailableViewRow", availableData);
      commit("setAvailableViewCurrentPage", currentPage);
      commit("setAvailableViewLastPage", lastPage);

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      commit("setLoadingAvailableTable", false);

      // alert
      showAlert(`¡${alertMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      //popup
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Delete Available
  async DeleteAvailableViewConfirm(
    { commit, dispatch },
    {
      token,
      deleteAvailable,
      showPage = 1,
    }: {
      token: string;
      deleteAvailable: {
        userId: number;
        availableId: number;
      };
      showPage: number;
    }
  ) {
    commit("setPopupLoadingMessage", "Eliminando disponibilidad...");
    commit("setPopupLoading", true);
    try {
      const dataInfo = await deleteAvailableApi.delete(
        `/${deleteAvailable.availableId}/1?page=${showPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const successMessage = dataInfo.data.message;
      dispatch("AfterDeleteAvailableView", { token, deleteAvailable });

      //alert
      showAlert(`¡${successMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Delete Available
  async DeleteAvailableView(
    { commit, dispatch },
    {
      token,
      deleteAvailable,
    }: {
      token: string;
      deleteAvailable: {
        userId: number;
        availableId: number;
      };
      showPage: number;
    }
  ) {
    const emulate = () => "sessionsFound";
    commit("setPopupLoadingMessage", "Eliminando disponibilidad...");
    commit("setPopupLoading", true);
    try {
      const emulateError = emulate();
      if (emulateError === "telexperticesFound00") throw Error("sessionsFound");
      const dataMessage = await deleteAvailableApi.delete(
        `/${deleteAvailable.availableId}/0`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch("AfterDeleteAvailableView", { token, deleteAvailable });
      const successMessage = dataMessage.data.message;

      // alert
      showAlert(`¡${successMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const viewError = showCatchError(errorParse);

      if (viewError === "sessionsFound") {
        commit("setAvailableReducerType", "availableDeleteOneAvailableConfirm");
        // popup
        commit("setPopupLoading", false);
        commit("setPopupLoadingMessage", "");
        commit(
          "setPopupTitle",
          "La disponibilidad ya cuenta con telexperticias agendadas"
        );
        commit(
          "setPopupMessage",
          "Al aceptar todas las telexperticias se reagendarán"
        );
      } else {
        //popup
        commit("setPopupLoading", false);
        commit("setPopupOpen", false);
        //alert
        showAlert(String(showCatchError(errorParse)), "error");
      }
    }
  },

  async UpdateUnavailableViewTablePage(
    { commit },
    { userId, showPage }: { token: string; userId: string; showPage: number }
  ) {
    // Table Loader
    commit("setLoadingUnavailableTable", true);
    try {
      const updateInfoAvailable = await availableByDoctorApi().get(
        `/${userId}?page=${showPage}`
      );

      const unavailableData =
        updateInfoAvailable.data.data.unavailabilities.data;
      const unavailableDataCurrentPage =
        updateInfoAvailable.data.data.unavailabilities.current_page;
      const unavailableDataLastPage =
        updateInfoAvailable.data.data.unavailabilities.last_page;
      const alertMessage = updateInfoAvailable.data.message;

      //unavailables
      commit("setUnavailableViewRow", unavailableData);
      commit("setUnavailableViewCurrentPage", unavailableDataCurrentPage);
      commit("setUnavailableViewLastPage", unavailableDataLastPage);

      // Table Loader
      commit("setLoadingUnavailableTable", false);

      // alert
      showAlert(`¡${alertMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setLoadingUnavailableTable", false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async UpdateAvailableViewTablePage(
    { commit },
    { userId, showPage }: { token: string; userId: string; showPage: number }
  ) {
    // Table Loader
    commit("setLoadingAvailableTable", true);
    try {
      const updateInfoAvailable = await availableByDoctorApi().get(
        `/${userId}?page=${showPage}`
      );
      const lastPage = updateInfoAvailable.data.data.availabilities.last_page;
      const currentPage =
        updateInfoAvailable.data.data.availabilities.current_page;
      const availableData = updateInfoAvailable.data.data.availabilities.data;
      const alertMessage = updateInfoAvailable.data.message;

      //availables
      commit("setAvailableViewRow", availableData);
      commit("setAvailableViewCurrentPage", currentPage);
      commit("setAvailableViewLastPage", lastPage);

      // Table Loader
      commit("setLoadingAvailableTable", false);

      // alert
      showAlert(`¡${alertMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async CreateUnavailability(
    { dispatch },
    {
      token,
      unavailableForm,
    }: {
      token: string;
      unavailableForm: IUnavailableCreate;
    }
  ) {
    openMainLoader(true);
    try {
      const unavailableInfo = await createUnavailability.post(
        `/`,
        { ...unavailableForm },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const unavailableInfoMessage = unavailableInfo.data.message;

      dispatch("UpdateUnavailableViewTablePage", {
        userId: unavailableForm.user_id,
        showPage: 1,
      });
      openMainLoader(false);

      // alert
      showAlert(`¡${unavailableInfoMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      openMainLoader(false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async DeleteAvailableById(
    { commit, dispatch },
    {
      token,
      availableCancelId,
      userId,
    }: { token: string; availableCancelId: number; userId: number }
  ) {
    // loader
    commit("setPopupLoadingMessage", 'Eliminando la "No disponibilidad"...');
    commit("setPopupLoading", true);
    try {
      const deleteAvailable = await deleteAvailableById.delete(
        `/${availableCancelId}/1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const alertMessage = deleteAvailable.data.message;

      dispatch("UpdateUnavailableViewTablePage", {
        userId,
        showPage: 1,
      });

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      // alert
      showAlert(`¡${alertMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async ShowAvailableByDoctor(
    { commit },

    doctorAvailableInfo: IAvailableInfoVardView,
    goToPage?: number
  ) {
    // loader
    openMainLoader(true);
    try {
      const availableRow = await availableByDoctorApi().get(
        `/${doctorAvailableInfo.id}?page=${goToPage}`
      );

      const lastPage = availableRow.data.data.availabilities.last_page;
      const currentPage = availableRow.data.data.availabilities.current_page;
      const availableData = availableRow.data.data.availabilities.data;
      const unavailableData = availableRow.data.data.unavailabilities.data;
      const unavailableDataCurrentPage =
        availableRow.data.data.unavailabilities.current_page;
      const unavailableDataLastPage =
        availableRow.data.data.unavailabilities.last_page;
      const infoCard = doctorAvailableInfo;
      const alertMessage = availableRow.data.message;

      //availables
      commit("setInfoAvailableCard", infoCard);
      commit("setAvailableViewCurrentPage", currentPage);
      commit("setAvailableViewRow", availableData);
      commit("setAvailableViewLastPage", lastPage);
      //unavailables
      commit("setUnavailableViewRow", unavailableData);
      commit("setUnavailableViewCurrentPage", unavailableDataCurrentPage);
      commit("setUnavailableViewLastPage", unavailableDataLastPage);
      openMainLoader(false);
      showAlert(`¡${alertMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);

      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async CreateAvailable(
    { commit },
    { token, infoData }: { token: string; infoData: IUpdateAvailable }
  ) {
    commit("setPopupLoadingMessage", "Creando disponibilidad...");
    commit("setPopupLoading", true);
    try {
      const createResponse = await availableApi.post(
        "/",
        { ...infoData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const alertMessage = createResponse.data.message;
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      showAlert(`¡${alertMessage}!`, "success");

      //redirect
      commit("setGoRedirectTo", "AvailableListView");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // available create list update
  async AvailableCreateListUpdate({ commit }, { token, centerRefId }) {
    //loader in table
    commit("setLoadingAvailableCreateList", true);
    try {
      const rowDoctorsInfo = await availableDoctorsByCenter.get(
        `/${centerRefId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const doctorsData = rowDoctorsInfo.data.data.data;
      const currentPage = rowDoctorsInfo.data.data.current_page;
      const lastPage = rowDoctorsInfo.data.data.last_page;
      const pageTo = rowDoctorsInfo.data.data.to;
      commit("setAvailableCreateRow", doctorsData);
      commit("setCurrentCreatePage", currentPage);
      commit("setTotalCreatePage", lastPage);
      commit("setCreateTo", pageTo);
      //loader in table
      openMainLoader(false);
      commit("setLoadingAvailableCreateList", false);

      //alert
      showAlert("¡Listado de médicos exitoso!", "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //loader
      openMainLoader(false);

      //loader in table
      commit("setLoadingAvailableCreateList", false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // available create list
  async AvailableCreateOptions({ commit }, { token }) {
    //loader
    openMainLoader(true);
    try {
      const selectData = await availableCenterRefOptions.get("/1", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const centersRefOptions = selectData.data.data;
      const referenceSelected = centersRefOptions[0].id;
      commit("setCenterRefOptions", centersRefOptions);

      await availableDoctorsByCenter
        .get(`/${referenceSelected}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const doctorsData = res.data.data.data;
          commit("setAvailableCreateRow", doctorsData);
        });

      //loader
      openMainLoader(false);

      //alert
      showAlert("¡Se han listado correctamente los médicos!", "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //loader
      openMainLoader(false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // After Delete Available By Doctor
  async AfterDeleteAvailableByDoctor(
    { commit },
    {
      token,
      centerRefId,
    }: {
      token: string;
      centerRefId: number;
    }
  ) {
    // popup
    commit(
      "setPopupLoadingMessage",
      "Actualizando listado de disponibilidad..."
    );

    try {
      const doctorsData = await availableAllApi.get(`/${centerRefId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const doctorInfoRow = doctorsData.data.data.data;

      commit("setAvailableRow", doctorInfoRow);

      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      commit("setLoadingAvailableTable", false);
      showAlert("¡Se ha eliminado con éxito la disponibilidad!", "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Delete Available By Doctor Confirm
  async DeleteAvailableByDoctorConfirm(
    { commit, dispatch },
    {
      token,
      userId,
      centerRefId,
    }: {
      token: string;
      userId: number;
      centerRefId: number;
    }
  ) {
    commit("setPopupLoadingMessage", "Eliminando disponibilidad...");
    commit("setPopupLoading", true);
    try {
      const { data } = await deleteAvailableByDoctorApi.delete(`/${userId}/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dataMessage = data.message;
      dispatch("AfterDeleteAvailableByDoctor", { token, centerRefId });

      //alert
      showAlert(`¡${dataMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      commit("setPopupLoading", false);
      commit("setPopupOpen", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Delete Available By Doctor
  async DeleteAvailableByDoctor(
    { commit, dispatch },
    {
      token,
      userId,
      centerRefId,
    }: {
      token: string;
      userId: number;
      centerRefId: number;
    }
  ) {
    commit("setPopupLoadingMessage", "Eliminando disponibilidad...");
    commit("setPopupLoading", true);
    const emulate = () => "sessionsFound";

    // Table Loader
    try {
      const emulateError = emulate();
      if (emulateError === "telexperticesFound00") throw Error("sessionsFound");
      const { data } = await deleteAvailableByDoctorApi.delete(`/${userId}/0`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataMessage = data.message;
      dispatch("AfterDeleteAvailableByDoctor", { token, centerRefId });

      //alert
      showAlert(`¡${dataMessage}!`, "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const viewError = showCatchError(errorParse);

      if (viewError === "sessionsFound") {
        commit("setAvailableReducerType", "availableDeleteByDoctorConfirm");
        // popup
        commit("setPopupLoading", false);
        commit("setPopupLoadingMessage", "");
        commit(
          "setPopupTitle",
          "La disponibilidad ya cuenta con telexperticias agendadas"
        );
        commit(
          "setPopupMessage",
          "Al aceptar todas las telexperticias se reagendarán"
        );
      } else {
        //popup
        commit("setPopupLoading", false);
        commit("setPopupOpen", false);
        //alert
        showAlert(String(showCatchError(errorParse)), "error");
      }
    }
  },

  // available list update
  async DoctorsByCenterSpecialty({ commit }, { goAvailableCreateUrl }) {
    // loader

    openMainLoader(true);
    if (goAvailableCreateUrl === "non-specialty") {
      commit("setCurrentAvailableCreatePage", 0);
      commit("setTotalAvailableCreatePage", 0);
      commit("setAvailableBySpecialityRow", []);
      openMainLoader(false);
      //table loader
      commit("setLoadingAvailableList", false);

      //alert
      showAlert(`!No posee un contrato activo!`, "success");

      return;
    }

    //in table loader
    commit("setLoadingAvailableList", true);

    try {
      const doctorData = await doctorsByCenterSpecialtyApi().get(
        `/${goAvailableCreateUrl}`
      );

      const doctorsDataRef = () => {
        if (!doctorData.data.data) {
          return [];
        } else {
          return doctorData.data.data.data.map(
            (doctor: IAvailableBySpecialityDoctor) => ({
              name:
                doctor.get_user_details.names +
                " " +
                doctor.get_user_details.surnames,
              speciality: doctor.specialty.description,
              specialityId: doctor.specialty.id,
              id: doctor.id,
            })
          );
        }
      };

      const lastPage = doctorData.data.data.last_page;
      const currentPage = doctorData.data.data.current_page;

      const alertMessage = doctorData.data.message;
      commit("setCurrentAvailableCreatePage", currentPage);
      commit("setTotalAvailableCreatePage", lastPage);
      commit("setAvailableBySpecialityRow", doctorsDataRef());
      //loader
      openMainLoader(false);
      //table loader
      commit("setLoadingAvailableList", false);

      //alert
      showAlert(
        `¡${
          doctorsDataRef().length > 0
            ? alertMessage
            : "No hay disponibilidades creadas"
        }!`,
        "success"
      );
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setAvailableBySpecialityRow", []);

      openMainLoader(false);
      //table loader
      commit("setLoadingAvailableList", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // available list update
  async AvailableListUpdate(
    { commit },
    { token, urlSearch }: { token: string; urlSearch: string }
  ) {
    // loader
    openMainLoader(true);

    if (urlSearch === "non-specialty") {
      commit("setCurrentAvailablePage", 0);
      commit("setTotalAvailablePage", 0);
      commit("setAvailableRow", []);
      openMainLoader(false);
      //table loader
      showAlert(`!No posee un contrato activo!`, "warning");
      return;
    }

    //in table loader
    commit("setLoadingAvailableList", true);
    try {
      const doctorData = await availableAllApi.get(`/${urlSearch}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const LastPage = doctorData.data.data.last_page;
      const currentPage = doctorData.data.data.current_page;
      const doctorsWithAvailable = doctorData.data.data.data;
      const alertMessage = doctorData.data.message;

      commit("setCurrentAvailablePage", currentPage);
      commit("setTotalAvailablePage", LastPage);
      commit("setAvailableRow", doctorsWithAvailable);
      //loader
      openMainLoader(false);
      //table loader
      commit("setLoadingAvailableList", false);

      //alert
      showAlert(
        `¡${
          doctorsWithAvailable.length > 0
            ? alertMessage
            : "No hay disponibilidades creadas"
        }!`,
        "success"
      );
    } catch (e) {
      commit("setAvailableRow", []);
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      openMainLoader(false);
      //table loader
      commit("setLoadingAvailableList", false);
      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // AVAILABLE LIST SELECTED OPTIONS
  async AvailableAllOptions({ commit }, { token }) {
    openMainLoader(true);

    try {
      const centerOptions = await availableCenterRefOptions.get("/1", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const alertMessage = centerOptions.data.message;
      const alertCenterOptions: [] = centerOptions.data.data;
      openMainLoader(false);
      commit("setCenterRefOptions", alertCenterOptions);
      //alert
      showAlert(
        alertCenterOptions.length > 0
          ? `¡${alertMessage}!`
          : "No hay opciones disponibles",
        "success"
      );
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //loader
      openMainLoader(false);

      //alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },
};

export default actions;
