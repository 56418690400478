import { computed, ref } from "vue";

const currentPreloadInfoStatus = ref<string>("preloadinfo/standby");

export const usePreloaderInfo = () => {
  const getCurrentPreloadInfoStatus = computed(
    () => currentPreloadInfoStatus.value
  );
  function setCurrentPreloadInfoStatus(newStatus: string) {
    currentPreloadInfoStatus.value = newStatus;
  }

  return {
    getCurrentPreloadInfoStatus,
    setCurrentPreloadInfoStatus,
  };
};
