import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useAlertV2 } from "@/composables/useAlertV2";
import { certificatesApi } from "@/apis/certificatesApi";
import { ErrorsUI } from "../contracts/actions";
import { ICertificateStateCustom } from "@/interfaces/customs/ICertificatesCustom";



const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2()
const validateEmpty = (valueType: string | number) => valueType === 0 || valueType === 'Todos' ? '' : valueType;



const actions: ActionTree<ICertificateStateCustom, StateInterface> = {

    async LoadCertificateList({ commit }, {
        page,
        rows,
        centerRefId,
        careCenterId,
        type,
        search
    }) {
        openMainLoader(true)
        try {
            const certificatesURL = `?page=${page}&rows=${rows}&reference_center_id=${centerRefId}&care_center_id=${careCenterId}&type=${type}&search=${search}`
            const certificateList = await certificatesApi().get(certificatesURL)

            const certificateListRef = certificateList.data
            const certificateMessage = certificateList.data.message

            const currentPage = certificateList.data.data.current_page
            const lastPage = certificateList.data.data.last_page

            commit('setCertificateList', certificateListRef)
            commit('setFirstCertificatePages', currentPage)
            commit('setLastCertificatePages', lastPage)
            commit('setCertificateListStatus', false)
            commit('setCertificateStatus', 'certificate/in-session')
      
            openMainLoader(false)
            showAlert(certificateMessage, 'success')
            openMainLoader(false);
        } catch (e) {
            const error = e as ErrorsUI;
            const message = error.message;
            const response = error.response;
            const errorParse = JSON.parse(JSON.stringify({ message, response }));
            commit('setCertificateList', [])
            commit('setCertificateListStatus', false)
            openMainLoader(false);
      
            showAlert(String(showCatchError(errorParse)), 'error')
        }
    },
};

export default actions;
