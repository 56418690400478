import { computed, ref } from "vue";

const currentRouteParams = ref();
export const useContractParams = () => {

    const getCurrentRouteParams = computed(()=> currentRouteParams.value )


  function setCurrentRouteParams(urlParams: string | undefined) {
    currentRouteParams.value = urlParams;
  }

  return {
    getCurrentRouteParams,
    setCurrentRouteParams
  };
};
