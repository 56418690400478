import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        /* User Profile */
        {
            path: '/mi-perfil',
            name: 'MyProfile',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfile View" */ '@/views/my-profile/MyProfile.vue'),
        },
        {
            path: '/mi-perfil-cambiar-contrasenia',
            name: 'MyProfilePassword',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MyProfilePassword View" */ '@/views/my-profile-password/MyProfilePassword.vue'),
        },
        {
            path: '/usuarios/admin-centro-atencion',
            name: 'AdminAtentionCenterList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminAtentionCenterList" */ '@/views/admin-atention-center/AdminAtentionCenterList.vue'),
        },
        {
            path: '/usuarios/admin-centro-atencion-crear',
            name: 'AdminAtentionCenterCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminAtentionCenterCreated" */ '@/views/admin-atention-center/sub-pages/admin-atention-center-created/AdminAtentionCenterCreated.vue'),
        },
        {
            path: '/usuarios/admin-centro-atencion-detalle',
            name: 'AdminAtentionCenterDetail',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminAtentionCenterDetail" */ '@/views/admin-atention-center/sub-pages/admin-atention-center-detail/AdminAtentionCenterDetail.vue'),
        },
        {
            path: '/usuarios/admin-centro-atencion-editar',
            name: 'AdminAtentionCenterEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminAtentionCenterEdit" */ '@/views/admin-atention-center/sub-pages/admin-atention-center-edit/AdminAtentionCenterEdit.vue'),
        },
        /* Admin Reference */
        {
            path: '/usuarios/admin-centro-referencia',
            name: 'AdminReferenceCenterList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminReferenceCenterList" */ '@/views/admin-reference-center/AdminReferenceCenterList.vue'),
        },
        {
            path: '/usuarios/admin-centro-referencia-crear',
            name: 'AdminReferenceCenterCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminReferenceCenterCreated" */ '@/views/admin-reference-center/sub-pages/admin-reference-center-created/AdminReferenceCenterCreated.vue'),
        },
        {
            path: '/usuarios/admin-centro-referencia-detalle',
            name: 'AdminReferenceCenterDetail',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminReferenceCenterDetail" */ '@/views/admin-reference-center/sub-pages/admin-reference-center-detail/AdminReferenceCenterDetail.vue'),
        },
        {
            path: '/usuarios/admin-centro-referencia-editar',
            name: 'AdminReferenceCenterEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "AdminReferenceCenterEdit" */ '@/views/admin-reference-center/sub-pages/admin-reference-center-edit/AdminReferenceCenterEdit.vue'),
        },
        /* Medicals */
        {
            path: '/usuarios/medicos',
            name: 'MedicalsList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MedicalsList" */ '@/views/medicals-list/MedicalsList.vue'),
        },
        {
            path: '/usuarios/medicos-crear',
            name: 'MedicalsCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MedicalsCreated" */ '@/views/medicals-list/sub-pages/medicals-created/MedicalsCreated.vue'),
        },
        {
            path: '/usuarios/medicos-editar',
            name: 'MedicalsEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MedicalsEdit" */ '@/views/medicals-list/sub-pages/medicals-edit/MedicalsEdit.vue'),
        },
        {
            path: '/usuarios/medicos-detalle',
            name: 'MedicalsDetail',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "MedicalsDetail" */ '@/views/medicals-list/sub-pages/medicals-detail/MedicalsDetail.vue'),
        },
        /* Nurses */
        {
            path: '/usuarios/enfermeras',
            name: 'NursesList',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "NursesList" */ '@/views/nurses-list/NursesList.vue'),
        },
        {
            path: '/usuarios/enfermeras-crear',
            name: 'NursesCreated',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "NursesCreated" */ '@/views/nurses-list/sub-pages/nurses-created/NursesCreated.vue'),
        },
        {
            path: '/usuarios/enfermeras-editar',
            name: 'NursesEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "NursesEdit" */ '@/views/nurses-list/sub-pages/nurses-edit/NursesEdit.vue'),
        },
        {
            path: '/usuarios/enfermeras-detalle',
            name: 'NursesDetail',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "NursesDetail" */ '@/views/nurses-list/sub-pages/nurses-detail/NursesDetail.vue'),
        },

        /* User Manual */

        {
            path: '/manual-usuario',
            name: 'UserManual',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import(/* webpackChunkName: "UserManual" */ '@/views/user-manual/UserManual.vue'),
        },
    ]