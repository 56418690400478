import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import {
  allIndicatorsReports,
  cancelIndicatorsReports,
  indicatorsYearAvaibilities,
  viewIndicator,
  indicatorsCreate,
} from "../../views/indicators-view/api/indicatorsApi";

import { useMainLoader } from "../../components/loader/hooks/useMainLoader";

import { IndicatorsUI } from "./state";
import { ErrorsUI } from "../contracts/actions";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";

import {
  IIindicatorsCreate,
  IIndicatorsList,
  IIndicatorsView,
} from "@/interfaces/global/IndicatorsUI";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();

const actions: ActionTree<IndicatorsUI, StateInterface> = {
  async AllIndicatorsReports(
    { commit },
    { indicatorListURL }: { indicatorListURL: string }
  ) {
    commit("setIndicatorListLoader", true);

    try {
      const indicatorsReports = await allIndicatorsReports().get(
        `/${indicatorListURL}`
      );
      const indicatorsListRef = !indicatorsReports.data.data.data
        ? []
        : indicatorsReports.data.data.data;
      const indicatorsListRow = [...indicatorsListRef].map(
        (indicator: IIndicatorsList) => ({
          id: indicator.id,
          dateYear: indicator.year,
          period: indicator.period,
          dataTime: indicator.time,
          dateGenerate:
            indicator.date_generation !== "Sin definir"
              ? `${indicator.date_generation.slice(
                0,
                10
              )} / ${indicator.date_generation.slice(11, 19)} hrs`
              : "Sin definir",
          requestBy: indicator.requested_by,
          status: indicator.status,
          centerRef: indicator.reference_center,
        })
      );

      const indicatorsListMessage = indicatorsReports.data.message;
      commit("setIndicatorListLoader", false);
      commit("setRowIndicators", indicatorsListRow);
      commit("setIndicatorsCurrentPage", indicatorsReports.data.data.current_page);
      commit("setIndicatorsLastPage", indicatorsReports.data.data.last_page);

      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `${indicatorsListRow.length > 0
          ? indicatorsListMessage
          : "No hay indicadores"
        }`,
        { root: true }
      );
      commit("general/setAlertType", "success", { root: true });
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setRowIndicators", []);
      // loader
      commit("setIndicatorListLoader", false);
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  async DispatchIndicatorsYearAvaibilities({ commit }) {
    try {
      const indicatorsYearsWithReports = await indicatorsYearAvaibilities().get(
        `/`
      );

      const indicatorsYearsRef =
        !indicatorsYearsWithReports.data.data ||
          indicatorsYearsWithReports.data.data.length === 0
          ? []
          : indicatorsYearsWithReports.data.data;

      const indicatorsYearsMessage = indicatorsYearsWithReports.data.message;

      commit("setIndicatorsYears", indicatorsYearsRef);

      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `${indicatorsYearsRef.length > 0
          ? indicatorsYearsMessage
          : "No hay reportes disponibles"
        }`,
        { root: true }
      );
      commit("general/setAlertType", "success", { root: true });
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setRowIndicators", []);
      // loader
      commit("setIndicatorListLoader", false);
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  async CancelIndicatorsReports(
    { commit, dispatch },
    { indicatorId, centerRefId }
  ) {
    commit(
      "general/setPopupLoadingMessage",
      `Cancelando indicador #${indicatorId}`,
      { root: true }
    );
    commit("general/setPopupLoading", true, { root: true });
    commit("setIndicatorListLoader", true);

    try {
      const indicatorsCancelReports = await cancelIndicatorsReports().post(
        `/${indicatorId}`
      );
      const indicatorsCancelMessage = indicatorsCancelReports.data.message;
      commit("setIndicatorListLoader", false);

      // loader
      commit("general/setPopupLoadingMessage", "");
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", indicatorsCancelMessage, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });

      dispatch("AllIndicatorsReports", {
        indicatorListURL: `${centerRefId}?page=1`,
      });
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // loader
      commit("setIndicatorListLoader", false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  async IndicatorsCreate({ commit }, bodyRequest: IIindicatorsCreate) {
    openMainLoader(true);

    try {
      const indicatorsRequestReports = await indicatorsCreate().post(
        `/`,
        bodyRequest
      );
      const indicatorsRequestMessage = indicatorsRequestReports.data.message;
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", indicatorsRequestMessage, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      commit("setIndicatorsRedirectTo", "IndicatorsList");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  async showIndicatorReports(
    { commit },
    { showIndicatorId, goToPage }: { showIndicatorId: string; goToPage: number }
  ) {
    commit("setIndicatorListLoader", true);

    if (!showIndicatorId) return;
    try {
      const indicatorsViewRes = await viewIndicator().get(
        `/${showIndicatorId}?page=${goToPage}`
      );

      const indicatorsViewReports: IIndicatorsView =
        indicatorsViewRes.data.data;

      const indicatorsDocsURL = {
        pdfURL: indicatorsViewReports.pdf_url,
        excelURL: indicatorsViewReports.excel_url,
      };
      const indicatorCard = {
        status: indicatorsViewReports.status,
        id: indicatorsViewReports.id,
        centerRef: indicatorsViewReports.reference_center,
        time: indicatorsViewReports.time,
        years: indicatorsViewReports.year,
        requestBy: indicatorsViewReports.requested_by,
        period: indicatorsViewReports.period,
        dateGeneration: indicatorsViewReports.date_generation + "hrs",
      };

      const indicatorsViewDatails = {
        currentPage: indicatorsViewReports.details.current_page,
        lastPage: indicatorsViewReports.details.last_page,
      };

      const indicatorsViewRow = () => {
        const viewRowsRef = indicatorsViewReports.details.data;
        if (!viewRowsRef || viewRowsRef.length === 0) {
          return [];
        } else {
          const indicatorsRowList = [...viewRowsRef].map((indicator) => ({
            service: indicator.speciality.description,
            totalSchedule: indicator.total_citations_1,
            appointmentAddDays: indicator.sum_days_assignment_appointment_2,
            opportunityDays: indicator.opportunity_days_3,
            totalDaysOfRequestedDate: indicator.sum_of_days_date_requested_4,
            opportunityRequestDays: indicator.opportunity_days_5,
            minAwaitDays: indicator.minimum_days_of_waiting_6,
            maxAwaitDays: indicator.maximum_days_of_waiting_7,
            hoursAvailables: indicator.hours_available_8,
          }));
          return indicatorsRowList;
        }
      };

      const indicatorsViewRowMessage = indicatorsViewRes.data.message;
      commit("setViewCardIndicator", indicatorCard);
      commit("setViewIndicatorsURL", indicatorsDocsURL);
      commit("setRowViewIndicators", indicatorsViewRow());
      commit("setViewIndicatorsCurrentPage", indicatorsViewDatails.currentPage);
      commit("setViewIndicatorsLastPage", indicatorsViewDatails.lastPage);
      commit("setViewIndicatorListLoader", false);
      // loader
      openMainLoader(false);

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit(
        "general/setAlertMessage",
        `${indicatorsViewRow().length > 0
          ? indicatorsViewRowMessage
          : "No hay indicadores"
        }`,
        { root: true }
      );
      commit("general/setAlertType", "success", { root: true });
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setRowViewIndicators", []);
      // loader
      commit("setIndicatorListLoader", false);
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },
};

export default actions;
