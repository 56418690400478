export interface AdminCentersInterface {
    success: null | boolean;
    data:    Datum[];
    message: string;
    service: string;
}

export interface AdminDetail {
    actions: string;
    email: string
    identification: string
    name: string;
    phone: string;
    status: string;
    surname: string;
}

export interface Datum {
    id:                number;
    email:             string;
    email_verified_at: null | string;
    status:            string;
    last_login:        null;
    logout:            null;
    expiry_date:       null;
    created_at:        null | string;
    updated_at:        null | string;
    user_types_id:     number;
    user_info_id:      number;
    centers_id:        number | null;
    info_users:        InfoUsers;
}

export interface InfoUsers {
    id:                number;
    created_at:        null | string;
    updated_at:        null | string;
    names:             string;
    surnames:          string;
    document:          string;
    phone:             string;
    signature:         null | string;
    photo:             null | string;
    professional_reg:  null | string;
    professional_type: null | string;
    gender: null | string;
}
export interface UsersState {
    adminCenters: AdminCentersInterface;
    medicals: AdminCentersInterface;
    adminCareEdit: AdminDetail;
    adminReferenceEdit: AdminDetail;
    colorAvatar: string;
    imgUser: string;
    isDashboard: boolean;
    dataTableTypeUser: dataPaginate
}

export interface dataPaginate {
    data: any[],
    current_page: number,
    last_page: number,
    per_page: number,
    total: number,
}

function state(): UsersState {
    return {
        adminCenters: {
            "success": null,
                "data": [
                    {
                        "id": 0,
                        "email": "",
                        "email_verified_at": "",
                        "status": "",
                        "last_login": null,
                        "logout": null,
                        "expiry_date": null,
                        "created_at": null,
                        "updated_at": null,
                        "user_types_id": 0,
                        "user_info_id": 0,
                        "centers_id": null,
                        "info_users": {
                            "id": 0,
                            "created_at": null,
                            "updated_at": null,
                            "names": "",
                            "surnames": "",
                            "document": "",
                            "phone": "",
                            "signature": null,
                            "photo": null,
                            "professional_reg": null,
                            "professional_type": null,
                            "gender": null
                        }
                    }
                ],
                "message": "",
                "service": ""
        },
        medicals: {
            "success": null,
                "data": [
                    {
                        "id": 0,
                        "email": "",
                        "email_verified_at": "",
                        "status": "",
                        "last_login": null,
                        "logout": null,
                        "expiry_date": null,
                        "created_at": null,
                        "updated_at": null,
                        "user_types_id": 0,
                        "user_info_id": 0,
                        "centers_id": null,
                        "info_users": {
                            "id": 0,
                            "created_at": null,
                            "updated_at": null,
                            "names": "",
                            "surnames": "",
                            "document": "",
                            "phone": "",
                            "signature": null,
                            "photo": null,
                            "professional_reg": null,
                            "professional_type": null,
                            "gender": null
                        }
                    }
                ],
                "message": "",
                "service": ""
        },
        adminCareEdit: {
        actions: '',
        email: '',
        identification: '',
        name: '',
        phone: '',
        status: '',
        surname: ''
        },
        adminReferenceEdit: {
            actions: '',
            email: '',
            identification: '',
            name: '',
            phone: '',
            status: '',
            surname: ''
        },
        colorAvatar: '',
        imgUser: '',
        isDashboard: false,
        dataTableTypeUser: {
            data: [],
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0,
        }
        
    }
}

export default state;