import { executeApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { CentersSelectorsState } from "@/interfaces/global/interfaces";

const CONFIG = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const URL_PATH = "/api/settings/centers";

const actions: ActionTree<CentersSelectorsState, StateInterface> = {
  async getCenters({ commit }) {
    try {
      const { data } = await executeApi().get(
        `${URL_PATH}/referenceCenters`
      );
      const { success, message } = data;

      commit("setCenters", data.data);

      return { success, message };
    } catch (error) {
      return errorException(error);
    }
  },
};

export default actions;
