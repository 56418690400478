import {
  ICenters,
  ILatestNotifications,
} from "@/interfaces/global/interfaces";

export interface Menu {
  number_childrens: number;
  childrens: MenuChildren[];
}

export interface MenuChildren {
  number_childrens: number;
  name: string;
  childrens: ChildrenChildren[];
}

export interface ChildrenChildren {
  name: string;
  path: string;
}
export interface AuthState {
  isAuthenticated: boolean;
  success: boolean;
  data: Data;
  message: string;
  tokenChangePassword: string;
  request: Record<'notifications', boolean>
}

export interface Centers {
  care_center: Center;
  reference_center: Center;
}

export interface Center {
  id: number;
  created_at: null;
  updated_at: null;
  name: string;
  nit: string;
  address: string;
  phone: string;
  email: string;
  logo: null;
  contact_name: string;
  contact_phone: string;
  status: string;
  centers_types_id: number;
}

export interface Data {
  user: any;
  centers: ICenters | Record<string, never>;
  first_login_or_not_change_password: boolean;
  authorization: Authorization;
  notifications: ILatestNotifications;
}

export interface Authorization {
  token: string;
  type: string;
}
export interface GetUserDetails {
  id: number;
  created_at: null;
  updated_at: null;
  names: string;
  surnames: string;
  document: string;
  phone: string;
  signature: null;
  photo: null;
  professional_reg: null;
  professional_type: null;
}

export interface GetUserPermissions {
  id: number;
  created_at: null;
  updated_at: null;
  role: string;
  menu: Menu;
  status: string;
}

function state(): AuthState {
  return {
    isAuthenticated: false,
    success: false,
    data: {
      user: [
        {
          id: 0,
          email: "",
          email_verified_at: "",
          status: "",
          last_login: "",
          logout: null,
          expiry_date: null,
          created_at: null,
          updated_at: "",
          user_types_id: 0,
          user_info_id: 0,
          centers_id: null,
          get_user_details: {
            id: 0,
            created_at: null,
            updated_at: null,
            names: "",
            surnames: "",
            document: "",
            phone: "",
            signature: null,
            photo: null,
            professional_reg: null,
            professional_type: null,
          },
          get_user_permissions: {
            id: 0,
            created_at: null,
            updated_at: null,
            role: "",
            menu: {
              number_childrens: 0,
              childrens: [
                {
                  number_childrens: 0,
                  name: "",
                  childrens: [
                    {
                      name: "",
                      path: "",
                    },
                  ],
                },
              ],
            },
            status: "",
          },
        },
      ],
      centers: {},
      first_login_or_not_change_password: false,
      authorization: {
        token: "",
        type: "",
      },
      notifications: {
        data: [],
        total: 0,
      },
    },
    message: "",
    tokenChangePassword: "",
    request: {
      notifications: false,
    }
  };
}

export default state;
