import { CentersSelectorsState } from "@/interfaces/global/interfaces";

function state(): CentersSelectorsState {
  return {
    referenceCenters: [],
    referenceCenterSelected: null,
    careCenterSelected: null
  };
}

export default state;
