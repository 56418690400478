import { ActionTree } from "vuex";
import { GeneralStoreUI } from "./state";
import { StateInterface } from "../index";
import {
  centerRefListApi,
  specialitiesByCenterRefApi,
  doctorsByCenterSpecialtyApiV3,
  careCenterListApi,
  allRegimen,
  servicesTypeApi,
  meetsResponseApi,
} from "@/apis/general/generalApi";
import { schedulesApiV3 } from "../../apis/schedulesApi";
import {
  doctorAvailabilitiesByMonthApi,
  doctorAvailabilitiesByDateApi,
  createScheduleApi,
} from "../../apis/availableApi";

import { allEPS } from "../../apis/general/generalApi";
import { ErrorsUI } from "../contracts/actions";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";

import {
  IDoctorAvailableDetailsV3,
  ITypeServices,
} from "@/interfaces/global/Availables";
import { ILoadDoctorOptions } from "@/interfaces/customs/ScheduleUI";
import { IPendingTelexpertiseV3 } from "@/interfaces/global/Telexpertise";
import { ISearchByPatient } from "@/interfaces/global/Patient";
import { IMeetResponse } from "@/interfaces/global/MeetResponse";
import { useTypes } from "@/composables/useTypes";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { serviceType, responseMeetStatus } = useTypes();

const actions: ActionTree<GeneralStoreUI, StateInterface> = {
  async DispatchMeetsResponse({ commit }, { type, session, token }) {
    commit("setMeetStatus", "counter/standby");

    try {
      const loadInfoMeet = await meetsResponseApi().get(
        `?type=${type}&session=${session}&token=${token}`
      );

      const infoMeetDataRef: IMeetResponse = loadInfoMeet.data.data;

      const infoMeetData = {
        patientName: infoMeetDataRef.patient.full_name,
        serviceType: infoMeetDataRef.current_schedule.session_type.name,
        initialHour: infoMeetDataRef.current_schedule.scheduled_at,
        finalHour: infoMeetDataRef.current_schedule.schedule_final_hour,
        fullDate: infoMeetDataRef.current_schedule.scheduled_at,
        specialtyName: infoMeetDataRef.specialty.description,
        urlMeet: `${infoMeetDataRef.current_schedule.schedule_url}`,
        doctorName: infoMeetDataRef.doctor
          ? infoMeetDataRef.doctor.name
          : "Sin definir",
        meetStatus: infoMeetDataRef.current_schedule.status,
      };

      const meetStatus =
        infoMeetDataRef.doctor && infoMeetData.meetStatus !== "Realizada"
          ? "counter/active"
          : "counter/disable";

      commit("setMeetResponse", infoMeetData);
      commit("setMeetStatus", meetStatus);
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;

      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit(
        "setMeetStatus",
        responseMeetStatus(String(showCatchError(errorParse))).type
      );
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  // Loads
  async DispatchServiceTypeOptions({ commit }) {
    commit("setServiceTypeOptionsLoader", true);
    try {
      const serviceTypeOptionsRef = await servicesTypeApi().get(`/`);
      const serviceTypeOptions = serviceTypeOptionsRef.data.data.map(
        (serviceType: ITypeServices) => ({
          label: serviceType.name,
          id: serviceType.id,
        })
      );

      commit("setServiceTypeOptions", serviceTypeOptions);
      commit("setServiceTypeOptionsLoader", false);
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setRowIndicators", []);
      // loader
      commit("setIndicatorListLoader", false);
      openMainLoader(false);
      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  async LoadCareCenterSelectorOptions({ commit }) {
    commit("setCareCenterSelectedLoader", true);
    try {
      const careCenterSelectorOptions = await careCenterListApi().get(
        `/2?status=active`
      );

      const careCenterList = careCenterSelectorOptions.data.data.filter(
        (centerRef: { referenceCenter: object | null }) =>
          centerRef.referenceCenter !== null
      );
      const setupCareCenters = [...careCenterList].map((careCenter) => ({
        label: careCenter?.name,
        id: careCenter?.id,
        centerRefId: careCenter.referenceCenter?.id,
        centerRefName: careCenter.referenceCenter?.name,
      }));

      commit("setCareCenterSelectorOptions", setupCareCenters);
      commit("setCareCenterSelectedLoader", false);

      const careCenterMessage = () => {
        if (setupCareCenters.length === 0 || !setupCareCenters) {
          commit("setShowAlert", true);
          commit("setAlertTitle", "");
          commit("setAlertMessage", "No hay centros de atención listados");
          commit("setAlertType", "");
        }
      };
      careCenterMessage();
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCareCenterSelectedLoader", false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  // Loads
  async LoadCenterRefSelectorOptions({ commit }) {
    commit("setCenterRefSelectedLoader", true);

    try {
      const centerRefSelectorOptions = await centerRefListApi().get(
        `/1?status=active`
      );
      const centerRefList =
        centerRefSelectorOptions.data.data.map(
          (option: { name: string; id: number }) => ({
            label: option?.name,
            id: option?.id,
          })
        ) || [];

      const firstValue =
        centerRefList.length > 0 ? centerRefList[0] : undefined;

      commit("setCenterRefSelectorOptions", centerRefList);
      commit("setFirstCenterRefOption", firstValue);
      commit("setCenterRefSelectedLoader", false);

      const centerRefMessage = () => {
        if (centerRefList.length === 0 || !centerRefList) {
          commit("setShowAlert", true);
          commit("setAlertTitle", "");
          commit("setAlertMessage", "No hay centros de referencia listados");
          commit("setAlertType", "");
        }
      };
      centerRefMessage();
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCenterRefSelectedLoader", false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async AllEPS({ commit }, { token }) {
    // loader
    commit("setEpsSelectorLoader", true);
    openMainLoader(true);

    try {
      const epsSelectorOptions = await allEPS.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const epsList = epsSelectorOptions.data.data;
      const epsMessage = epsSelectorOptions.data.message;

      const epsOptions = [...epsList].map((eps) => {
        return {
          value: eps.id,
          label: eps.name,
        };
      });

      // selectors - center
      commit("setEpsSelectorOpions", epsOptions);
      commit("setEpsSelectorLoader", false);
      // loader
      openMainLoader(false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", `¡${epsMessage}!`);
      commit("setAlertType", "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async RegimenEPS({ commit }, { token }) {
    // loader
    openMainLoader(true);
    commit("setRegimenSelectorLoader", true);

    try {
      const regimenSelectorOptions = await allRegimen.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const regimenList = regimenSelectorOptions.data.data;
      const regimenMessage = regimenSelectorOptions.data.message;

      const regimenOptions = [...regimenList].map((eps) => {
        return {
          value: eps.id,
          label: eps.name,
        };
      });

      // selectors - center
      commit("setRegimenSelectorOptions", regimenOptions);
      commit("setRegimenSelectorLoader", false);
      // loader
      openMainLoader(false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", `¡${regimenMessage}!`);
      commit("setAlertType", "success");
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async LoadSpecialitiesByCenterRefOptions({ commit }, { token, centerRefId }) {
    try {
      const specialityOptions = await specialitiesByCenterRefApi.get(
        `/${centerRefId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const specialityList = specialityOptions.data.data.map(
        (option: { description: string; id: number }) => ({
          label: option.description,
          id: option.id,
        })
      );
      return { status: "success", specialityList };
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setSpecialitiesByCenterRefLoader", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async LoadDoctorBySpecialityOptions(
    { commit },
    { centerRefId, serviceTypeId, specialityId }: ILoadDoctorOptions
  ) {
    try {
      const typeId = !serviceTypeId ? "" : serviceTypeId;
      const bySpecialtyURL = `/${centerRefId}?specialty=${specialityId}&service=${typeId}`;
      const doctorBySpecialityOptions =
        await doctorsByCenterSpecialtyApiV3().get(`${bySpecialtyURL}`);
      const doctorsList = doctorBySpecialityOptions.data.data.data.map(
        (option: IDoctorAvailableDetailsV3) => ({
          label: option.name,
          id: option.id,
        })
      );

      return { status: "success", doctorsList };
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setDoctorBySpecialityLoader", false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  // WORKING HERE !!!!
  async LoadTelexperticesPending(
    { commit },
    { centerRefId, typeId, specialityId }
  ) {
    commit("setTelexperticesAvailablesLoader", true);
    try {
      const pendingTelexperticeURL = `?reference_center=${centerRefId}&specialty=${specialityId}&type=${typeId}`;

      const telexperticeAvailable = await schedulesApiV3().get(
        `v3/sessions${pendingTelexperticeURL}`
      );

      const telexperticeRef: IPendingTelexpertiseV3[] =
        telexperticeAvailable.data.data;

      const telexperticeList = telexperticeRef.map((option) => ({
        label: option.id,
        telexpertiseId: option.id,
        description: option.current_schedule.scheduled_at_formatted,
        priority: option.priority,
        patientId: option.patient.id,
        patientName: `${option.patient.name} ${option.patient.last_name}`,
        patientDocument: option.patient.document,
        careCenter: option.care_center.name,
        careCenterId: option.care_center.id,
        specialityName: option.specialty.description,
        specialityId: option.specialty.id,
        creatorName: !option.doctor ? "Sin definir" : option.doctor.name,
        creatorId: !option.doctor ? -1 : option.doctor.id,
        typeId: option.current_schedule.session_type.id,
      }));

      return { status: "success", telexperticeList };
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setTelexperticesAvailablesLoader", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async LoadingAvailableByMonth(
    { commit },
    { doctorId, monthSelected, yearSelected, serviceType }
  ) {
    commit("setScheduleCalendarLoader", true);
    const composedUrl = `/${doctorId}/${
      monthSelected + 1
    }/${yearSelected}?type_id=${serviceType}`;
    try {
      const availableInCalendar = await doctorAvailabilitiesByMonthApi().get(
        composedUrl
      );

      const availableInCalendarList = availableInCalendar.data.data;
      const availableInCalendarMessage = availableInCalendar.data.message;
      // selectors - specialities
      commit("setCurrentScheduleComponent", "CalendarSchedule");
      commit("setAvailableByCalendar", availableInCalendarList);

      commit("setScheduleCalendarLoader", false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit(
        "setAlertMessage",
        availableInCalendarList.length > 0
          ? availableInCalendarMessage
          : "Este centro no tiene especialidades asignadas"
      );
      commit(
        "setAlertType",
        availableInCalendarList.length > 0 ? "success" : "warning"
      );
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setScheduleCalendarLoader", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async LoadingAvailableByDay({ commit }, { doctorId, fullYear, typeId }) {
    commit("setScheduleCalendarLoader", true);
    try {
      const availableByDay = await doctorAvailabilitiesByDateApi().get(
        `/${doctorId}/${fullYear}?type_id=${typeId}`
      );
      const availableInCalendarList = availableByDay.data.data;
      const availableInCalendarMessage = availableByDay.data.message;
      commit("setAvailablesHoursByDay", availableInCalendarList);
      if (availableInCalendarList.length > 0) {
        commit("setCurrentScheduleComponent", "CalendarByDay");
      }

      commit("setScheduleCalendarLoader", false);
      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit(
        "setAlertMessage",
        availableInCalendarList.length > 0
          ? availableInCalendarMessage
          : "¡No hay ninguna disponibilidad!"
      );
      commit(
        "setAlertType",
        availableInCalendarList.length > 0 ? "success" : "warning"
      );
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setScheduleCalendarLoader", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
    }
  },

  async CreateSchedule(
    { commit },
    {
      session_id,
      user_id,
      date,
      type,
    }: {
      session_id: number;
      user_id: number;
      date: string;
      type: number;
    }
  ) {
    commit("setPopupLoading", true);
    commit("setPopupLoadingMessage", `Agendando ${serviceType(type)}...`);
    try {
      const createSchedule = await createScheduleApi().post(`/v3`, {
        session_id,
        user_id,
        date,
        type,
      });

      const createScheduleMessage = createSchedule.data.message;
      commit("setCurrentScheduleComponent", "CalendarSchedule");
      commit("setReloadScheduleStatus", "schedule/update");

      commit("setPopupLoading", false);
      commit("setPopupLoadingMessage", "");
      commit("setPopupOpen", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit(
        "setAlertMessage",
        createScheduleMessage.length > 0
          ? createScheduleMessage
          : "Este centro no tiene especialidades asignadas"
      );
      commit(
        "setAlertType",
        createScheduleMessage.length > 0 ? "success" : "warning"
      );
      return { status: "success" };
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      //close popup
      commit("setPopupLoading", false);
      commit("setPopupLoadingMessage", "");
      commit("setPopupOpen", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
      return { status: "error" };
    }
  },

  async DipatchSearchByPatient(
    { commit },
    { centerRefId, document }: { centerRefId: number; document: number }
  ) {
    commit("setSearchByPatient", true);
    try {
      const searchPatient = await createScheduleApi().get(
        `/v3/patient?document=${document}&reference_center=${centerRefId}`
      );

      const patientInfoRef: ISearchByPatient = searchPatient.data.data;
      const patientInfo = {
        patientId: patientInfoRef.id,
        patientName: patientInfoRef.full_name,
        careCenterName: patientInfoRef.care_center.name,
        currentEPS: patientInfoRef.eps.name,
        age: patientInfoRef.age,
        phone: patientInfoRef.phone,
        email: patientInfoRef.email,
      };

      const message = searchPatient.data.message;

      commit("setPatientInfo", patientInfo);

      // alert

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", message);
      commit("setAlertType", "success");
      commit("setSearchByPatient", false);
      return 'success'
    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit("setPatientInfo", undefined);


      //close popup
      commit("setPopupLoading", false);
      commit("setPopupLoadingMessage", "");
      commit("setPopupOpen", false);

      // alert
      commit("setShowAlert", true);
      commit("setAlertTitle", "");
      commit("setAlertMessage", showError(errorParse));
      commit("setAlertType", "error");
      commit("setSearchByPatient", false);
      return 'error'
    }
  },
};

export default actions;

function showError({
  message,
  response,
}: {
  message: string | undefined;
  response:
    | {
        data:
          | {
              message: string | undefined;
            }
          | undefined;
      }
    | undefined;
}) {
  if (response) {
    return response.data?.message
      ? response.data?.message
      : "¡Error sin definir!";
  } else {
    return message;
  }
}
