import { GetterTree } from "vuex";
import { CentersSelectorsState } from "@/interfaces/global/interfaces";
import { StateInterface } from "../index";

const getters: GetterTree<CentersSelectorsState, StateInterface> = {
  getReferenceCenterSelected(state) {
    return state.referenceCenterSelected;
  },
  getCareCenterSelected(state) {
    return state.careCenterSelected;
  },
};

export default getters;
