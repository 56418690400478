import { MutationTree } from "vuex";
import { CentersSelectorsState } from "@/interfaces/global/interfaces";

const mutation: MutationTree<CentersSelectorsState> = {
  setCenters(
    state: CentersSelectorsState,
    centers:
      | Array<{
          id: number;
          name: string;
          nit: string;
          careCenters: Array<{
            id: number;
            name: string;
            nit: string;
            reference_center_id: number;
            referenceCenter: {
              id: number;
              name: string;
            };
          }>;
        }>
      | []
  ) {
    state.referenceCenters = centers;
    if (centers.length > 0) {
      // state.referenceCenterSelected = centers[0].id;
      // state.careCenterSelected = centers[0].careCenters[0].id;

      if (sessionStorage.getItem("user")) {
        const user = JSON.parse(sessionStorage.getItem("user") as string);
        if ([2, 3, 5, 6, 7].includes(user[0].user_types_id)) {
          state.referenceCenterSelected = centers[0].id;
        }
        if ([3, 6, 7].includes(user[0].user_types_id)) {
          state.careCenterSelected = centers[0].careCenters[0].id;
        }
      }
    }
  },
  setReferenceCenter(state: CentersSelectorsState, rf: number | null) {
    state.referenceCenterSelected = rf;
  },
  setCareCenter(state: CentersSelectorsState, cc: number | null) {
    state.careCenterSelected = cc;
  },
};

export default mutation;
